(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/auth/assets/javascripts/auth-roles.js') >= 0) return;  svs.modules.push('/components/marketplace-data/auth/assets/javascripts/auth-roles.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  statuses
} = svs.components.marketplaceData.auth;
const PERMISSIONS = ['INVITE_MEMBER', 'DELETE_MEMBER', 'GIVE_PRIVILEGES', 'CREATE_TEAM', 'END_TEAM', 'LEAVE_TEAM', 'PREPARE_GAME', 'DELETE_GAME', 'MODERATE_CHAT', 'RETAIL_ADMINISTRATE', 'VIEW_MEMBERS', 'VIEW_GAME', 'VIEW_CHAT', 'PURCHASE_FRACTION', 'VIEW_HISTORY'].reduce((auths, permission, index) => _objectSpread(_objectSpread({}, auths), {}, {
  [permission]: 1 << index
}), {});

const ROLES = {
  CAPTAIN: {
    permission: PERMISSIONS.INVITE_MEMBER | PERMISSIONS.DELETE_MEMBER | PERMISSIONS.GIVE_PRIVILEGES | PERMISSIONS.PREPARE_GAME | PERMISSIONS.DELETE_GAME | PERMISSIONS.PURCHASE_FRACTION | PERMISSIONS.END_TEAM | PERMISSIONS.VIEW_MEMBERS | PERMISSIONS.VIEW_GAME | PERMISSIONS.VIEW_CHAT | PERMISSIONS.VIEW_HISTORY,
    name: 'Lagkapten',
    id: 1
  },
  ADMIN: {
    permission: PERMISSIONS.PREPARE_GAME | PERMISSIONS.LEAVE_TEAM | PERMISSIONS.PURCHASE_FRACTION | PERMISSIONS.VIEW_MEMBERS | PERMISSIONS.VIEW_GAME | PERMISSIONS.VIEW_CHAT | PERMISSIONS.VIEW_HISTORY,
    name: 'Speladmin',
    id: 2
  },
  MEMBER: {
    permission: PERMISSIONS.LEAVE_TEAM | PERMISSIONS.PURCHASE_FRACTION | PERMISSIONS.VIEW_MEMBERS | PERMISSIONS.VIEW_GAME | PERMISSIONS.VIEW_CHAT | PERMISSIONS.VIEW_HISTORY,
    name: 'Medlem',
    id: 3
  },
  SPECTATOR: {
    permission: PERMISSIONS.VIEW_GAME,
    name: 'Åskådare'
  },
  RETAIL_OWNER: {
    permission: PERMISSIONS.CREATE_TEAM | PERMISSIONS.END_TEAM | PERMISSIONS.PREPARE_GAME | PERMISSIONS.MODERATE_CHAT | PERMISSIONS.RETAIL_ADMINISTRATE | PERMISSIONS.VIEW_GAME | PERMISSIONS.VIEW_CHAT,
    name: 'Ombudsägare'
  },
  RETAIL_GAMER: {
    permission: PERMISSIONS.CREATE_TEAM | PERMISSIONS.END_TEAM | PERMISSIONS.PREPARE_GAME | PERMISSIONS.MODERATE_CHAT | PERMISSIONS.RETAIL_ADMINISTRATE | PERMISSIONS.VIEW_GAME | PERMISSIONS.VIEW_CHAT,
    name: 'Spelansvarig'
  },
  RETAIL_WORKER: {
    permission: PERMISSIONS.CREATE_TEAM | PERMISSIONS.END_TEAM | PERMISSIONS.PREPARE_GAME | PERMISSIONS.MODERATE_CHAT | PERMISSIONS.VIEW_GAME | PERMISSIONS.VIEW_CHAT,
    name: 'Medarbetare'
  },
  NONE: {
    permission: 0,
    name: ''
  }
};
const ROLES_BY_NAME = Object.keys(ROLES).reduce((roles, role) => _objectSpread(_objectSpread({}, roles), {}, {
  [role]: role
}), {});
const ROLES_BY_ID = Object.keys(ROLES).reduce((roles, role) => _objectSpread(_objectSpread({}, roles), {}, {
  [ROLES[role].id]: role
}), {});
function getRole(role, status) {
  return statuses.isPendingRemoval(status) ? ROLES_BY_NAME.SPECTATOR : ROLES_BY_ID[role];
}
function hasPermission(role, permission) {
  const roleObject = ROLES[role] || ROLES[ROLES_BY_ID[role]];
  if (!roleObject) {
    throw new Error('Role does not exist');
  }
  return Boolean(roleObject.permission & permission);
}
function isValidRole(roleIdOrKey) {
  const checkRole = ROLES[roleIdOrKey] || ROLES[ROLES_BY_ID[roleIdOrKey]];
  if (checkRole === ROLES.NONE) {
    return false;
  }
  return Boolean(checkRole);
}
function withRole(role) {
  const rolePermission = ROLES[role].permission;
  if (typeof rolePermission === 'undefined') {
    throw new Error("Role does not exist: ".concat(role));
  }
  return action => Boolean(rolePermission & action);
}
function getId(role) {
  return ROLES[role].id;
}
function getDisplayName(role) {
  return ROLES[role].name;
}
function isRole(roleId, role) {
  if (!ROLES[role]) {
    throw new Error("Role does not exist: ".concat(role));
  }
  if (!ROLES[role].id) {
    throw new Error("Role does not have an id: ".concat(role));
  }
  return ROLES[role].id === roleId;
}
setGlobal('svs.components.marketplaceData.auth.roles', {
  getId,
  getRole,
  isRole,
  withRole,
  hasPermission,
  getDisplayName,
  isValidRole,
  PERMISSIONS,
  ROLES_BY_ID,
  ROLES: ROLES_BY_NAME
});

 })(window);