(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/auth/assets/javascripts/auth-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/auth/assets/javascripts/auth-actions.js');
"use strict";

const LOGOUT = 'LOGOUT';
const LOGIN = 'LOGIN';
const logOut = () => ({
  type: LOGOUT
});
const logIn = () => ({
  type: LOGIN
});
setGlobal('svs.components.marketplaceData.auth.actions', {
  LOGOUT,
  logOut,
  LOGIN,
  logIn
});

 })(window);