(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/auth/assets/javascripts/auth-statuses.js') >= 0) return;  svs.modules.push('/components/marketplace-data/auth/assets/javascripts/auth-statuses.js');
"use strict";


const STATUSES = {
  ACTIVE_MEMBER: 'ACTIVE_MEMBER',
  PENDING_REMOVAL: 'PENDING_REMOVAL'
};
const STATUSES_BY_ID = {
  1: STATUSES.ACTIVE_MEMBER,
  2: STATUSES.PENDING_REMOVAL
};
const isPendingRemoval = status => status === STATUSES.PENDING_REMOVAL;
const getStatusById = status => STATUSES_BY_ID[status];
setGlobal('svs.components.marketplaceData.auth.statuses', {
  isPendingRemoval,
  getStatusById,
  STATUSES
});

 })(window);